<template>
    <div>
        <div class="main-title">知识扩展列表</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">知识扩展</li>
                    </ul>
                </div>
            </div>
            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l" aria-label="标题">
                   <el-input v-model="title" placeholder="请输入标题"></el-input>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
					<el-button type="primary" size="small" @click="addKnowledge()">添加</el-button>
                </span>

            </div>

            <div class="table-content">
                <el-table
                        :header-cell-style="{padding:0}"
                        :cell-class-name="rowItem"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        @current-change="handleCurrentChange"
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80"
							align="center"
							>
                    </el-table-column>
                    <el-table-column
                        label="标题"
                        property="title"
                    >
                    </el-table-column>
					<el-table-column
					    label="页数"
					    property="page_count"
						width="200"
						align="center"
					>
					</el-table-column>
                    <el-table-column
                            property="created_at"
                            label="创建时间"
                            width="300">
                    </el-table-column>
					<el-table-column
					        property="updated_at"
					        label="更新时间"
					        width="300">
					</el-table-column>
                    <el-table-column
                        label="操作员"
                        property="user"
                        width="200"
                    >
                    </el-table-column>
                    <el-table-column
                            width="300"
                            label="操作">
                        <template slot-scope="scope">
                            <span class="margin-r-sm">
                              <el-link slot="reference" type="primary" @click="showDetail(scope.row.id)">预览</el-link>
                            </span>
							<span class="margin-r-sm">
								<el-link slot="reference" type="primary" @click="editKnowledge(scope.row.id)">编辑</el-link>
							</span>
                            <span>
                                <el-popconfirm
                                        icon="el-icon-info"
                                        icon-color="red"
                                        title="删除后将不可恢复，确定删除吗？"
                                        @confirm="deleted(scope.row.id)"
                                >
                                  <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="table-batch" >
                <span class="fl-l" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
    export default {
        name: "knowledgeList_1",
        data(){
            return {
				type:2, // 知识扩展
                page:1,
                pageSize:10,
                total:0,
                loading:false,
                title: '',
                tableData: [],
                currentRow: null,
				importing:false,
            }
        },
      created() {
          this.getList();
      },
      methods:{
            ...mapActions('knowledge',['getKnowledgeList','delKnowledge']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem(){
                return 'row-item';
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            // 获得列表
            async getList(){
              this.loading = true
              var vm = this
              const { res_info, data } = await this.getKnowledgeList({type:vm.type,title:vm.title ,page: vm.page, pageSize: vm.pageSize});
              this.loading = false

              if (res_info !== 'ok') return

              this.total = data.total
              this.tableData = data.list
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            // 预览
            showDetail(val){
              this.$router.push('/knowledge_2/detail/'+val)
            },
           // 删除
            async deleted(val){
              const { res_info, data } = await this.delKnowledge(val)
              if (res_info !== 'ok') return
              this.$message.success('删除成功!')
              await this.getList()
            },
			// 添加
			addKnowledge(){
				this.$router.push('/knowledge_2/add')
			},
			// 编辑
			editKnowledge(id){
				this.$router.push('/knowledge_2/edit/'+id)
			}
        }
    }
</script>
